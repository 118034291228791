import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';

// css 기본 설정
import 'setting/font.css';
import 'setting/layout.css';
import 'css/index.css';
import 'css/slider.css';
import 'react-spring-bottom-sheet/dist/style.css';
import 'react-tooltip/dist/react-tooltip.css'; // tooltip css

// 임시로 service worker 비활성화
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log(
          'Service Worker registered with scope:',
          registration.scope,
        );
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
