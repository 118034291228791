import { Locale } from 'types/types';

export const convertTo12HourFormat = (hour: number) => {
  if (hour === 0) return 0;
  if (hour > 12) return hour - 12;
  return hour;
};
export const getMeridiem = (hour: number) => (hour >= 12 ? 'PM' : 'AM');

/**
 * 주어진 날짜/시간 문자열을 커스텀 형식으로 변환합니다.
 *
 * @param {string} dateTimeString - 변환할 날짜/시간 문자열 (YYYY-MM-DD HH:MM:SS 형식)
 * @returns {string} 변환된 날짜/시간 문자열 (YY.MM.DD | HH : MM 형식)
 */
export function convertDateTimeToCustomFormat(
  dateTimeString: string,
  locale?: Locale,
) {
  // Date 객체 생성
  const date = new Date(dateTimeString);
  if (isNaN(date.getTime())) return '-';

  // 연도, 월, 일을 두 자리 숫자 형식으로 추출
  const year = date.getFullYear().toString().substring(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  // 시간과 분을 두 자리 숫자 형식으로 추출
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // 변환된 형식으로 문자열 반환
  if (!locale || locale === 'KR')
    return `${year}.${month}.${day} | ${hours}:${minutes}`;
  if (locale === 'ENG') return `${month}/${day}/${year} | ${hours}:${minutes}`;
  if (locale === 'VNM') return `${day}/${month}/${year} | ${hours}:${minutes}`;
}

/**
 * 주어진 날짜/시간 문자열을 현재시간으로부터 상대적인 시간으로 반환합니다.
 *
 * @param {string} dateTimeString - 변환할 날짜/시간 문자열 (YYYY-MM-DD HH:MM:SS 형식)
 * @returns {string} - ~시간 전, ~주 전
 */
export function timeAgo(
  inputTime: string,
  translate: (
    key: string,
    variables?:
      | {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          [key: string]: any;
        }
      | undefined,
  ) => string,
) {
  const inputDate = new Date(inputTime);
  const now = new Date();
  const diff = Math.abs(now.getTime() - inputDate.getTime());
  if (isNaN(diff)) return '-';
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return translate('_global_years_ago', { years });
  } else if (months > 0) {
    return translate('_global_months_ago', { months });
  } else if (weeks > 0) {
    return translate('_global_weeks_ago', { weeks });
  } else if (days > 0) {
    return translate('_global_days_ago', { days });
  } else if (hours > 0) {
    return translate('_global_hours_ago', { hours });
  } else if (minutes > 0) {
    return translate('_global_minutes_ago', { minutes });
  } else {
    return translate('_global_seconds_ago', { seconds });
  }
}

export function getLocaleTimeString(
  dateTime: string, //"2023-09-21 15:11:35"
  locale: Locale,
  translate: (
    key: string,
    variables?:
      | {
          [key: string]: any;
        }
      | undefined,
  ) => string,
) {
  const inputDate = new Date(dateTime);
  // const month = inputDate.getMonth() + 1;
  // const date = inputDate.getDate();
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  switch (locale) {
    case 'KR':
    case 'VNM':
      return `${translate('_global_hours', { hours })} ${translate(
        '_global_minutes',
        { minutes },
      )}`;
    case 'ENG':
      return `${hours}:${minutes}`;
  }
}
